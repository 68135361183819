

:root {
    --yella: rgb(233, 192, 56)
}


:root {
    --glow-color: hsl(44, 97%, 64%);
}

.ready-button {
    width: 25%;
    font-size: 2rem;
}

body {
color: var(--glow-color);
font-size: 3rem;
}

.p1-is-ready, .p2-is-ready {
    border: 5px solid green;
}
