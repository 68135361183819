

.game-board {
    margin: 10%;
    background: rgb(100, 120, 120);
    width: 500px;
    height: 500px;
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
    gap: 1rem;
}

.tile {
    border: none;
    background-color: white;
    color: black;
    font-size: 6rem;
}

.player-text-box {
    display: flex;
    align-content: center;
    height: 2rem;
    font-size: 1.5rem;
    border: solid .1rem black;
    width: 50vw;
    border-radius: 4px;
    display: inline-block;
}